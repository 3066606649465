// Import all plugins
import * as bootstrap from "bootstrap";
window.$ = require("jquery");

// reduce opacity if sub menu is open
function fadeNav() {
  if ($(".dropdown-toggle").hasClass("show")) {
    $(".nav-item > a").not(".nav-item.dropdown > a").addClass("color-dark-grey");
  } else {
    $(".nav-item > a").removeClass("color-dark-grey");
  }
}
//show sub menu if on sub page
function activeSub() {
  const activeMenu = $(".navbar-nav").find(".active").attr('class');
  if(activeMenu.indexOf("dropdown-item") >= 0){
    $(".dropdown-item").not(".active").addClass("color-dark-grey");
    $(".dropdown-toggle").addClass("show").attr("aria-expanded='true'");
    $(".dropdown-menu").addClass("show");
  } else {
    $(".dropdown-item").removeClass("color-dark-grey");
  }


}
$(".nav-item, .navbar-toggler").click(function () {
  activeSub();
  fadeNav();
});

//on button click, show correct plan
$(".option-button").click(function () {
  $(".option-button").removeClass("active");
  $(this).addClass("active");
  var getUrl = window.location;
  var baseUrl = getUrl.protocol + "//" + getUrl.host + "/corsham/"; 
  

  const id = $(this).attr("id");
  const slideFrame = $(this).closest(".tns-item").attr("id");
  $("#" + slideFrame)
    .find("img")
    .attr("src", baseUrl+"/sliders/" + id + ".png");

  if ($(this).hasClass("show-extra-info")) {
    $(".extra-info").slideDown();
  } else {
    $(".extra-info").slideUp();
  }

  if($(this).hasClass("show-extra-info-high")){
    $(".extra-info").slideUp();
    const Highid = $(this).attr('id');
    $("."+Highid+"-extra-info").slideDown();
  }
});

document.addEventListener("DOMContentLoaded", function(){

  el_autohide = document.querySelector('.autohide');  
  if(el_autohide){
    var last_scroll_top = 0;
    window.addEventListener('scroll', function() {
          let scroll_top = window.scrollY;
         if(scroll_top < last_scroll_top) {
              el_autohide.classList.remove('scrolled-down');
              el_autohide.classList.add('scrolled-up');
          }
          else {
              el_autohide.classList.remove('scrolled-up');
              el_autohide.classList.add('scrolled-down');
          }
          last_scroll_top = scroll_top;
    }); 
  }

}); 